import axios from "axios";

const roofTypeFilter = document.querySelectorAll('.roof-system-search-results .roof_type_filter');
const waterproofingTypeFilter = document.querySelectorAll('.roof-system-search-results .waterproofing_type_filter');
const insulationTypeFilter = document.querySelectorAll('.roof-system-search-results .insulation_type_filter');
const deckTypeFilter = document.querySelectorAll('.roof-system-search-results .deck_type_filter');
const newBuildFilter = document.querySelectorAll('.roof-system-search-results .new_build_filter');
const refurbishmentFilter = document.querySelectorAll('.roof-system-search-results .refurbishment_filter');
const fixingTypeFilter = document.querySelectorAll('.roof-system-search-results .fixing_type_filter');
const guaranteePeriodFilter = document.querySelectorAll('.roof-system-search-results .guarantee_period_filter');
const uniclassFilter = document.querySelectorAll('.roof-system-search-results .uniclass_filter');
const searchFilterClear = document.getElementById('accuroof-roof-system-results__clear');

const roofSystemResultsWrapper = document.getElementById('roofSystemResultsWrapper');
let current_page = roofSystemResultsWrapper?.dataset.page;
let max_pages = roofSystemResultsWrapper?.dataset.max;
let per_page = roofSystemResultsWrapper?.dataset.perPage;
let filter_type = roofSystemResultsWrapper?.dataset.filterType;
let postType = roofSystemResultsWrapper?.dataset.postType;

let currentUrl = window.location.href

const ajaxRoofSystemSearchResults = () => {
    document.addEventListener('DOMContentLoaded', function() {
        if (roofSystemResultsWrapper) {
            ajaxPaginate();
        }

        if (document.querySelectorAll('.roof-system-search-results .roof_type_filter').length > 0) {
            filterPostsByRoofTypeRoofSystem();
        }

        if (document.querySelectorAll('.roof-system-search-results .waterproofing_type_filter').length > 0) {
            filterPostsByWaterproofingTypeRoofSystem();
        }

        if (document.querySelectorAll('.roof-system-search-results .insulation_type_filter').length > 0) {
            filterPostsByInsulationTypeRoofSystem();
        }

        if (document.querySelectorAll('.roof-system-search-results .deck_type_filter').length > 0) {
            filterPostsByDeckTypeRoofSystem();
        }

        if (document.querySelectorAll('.roof-system-search-results .new_build_filter').length > 0) {
            filterPostsByNewBuildRoofSystem();
        }

        if (document.querySelectorAll('.roof-system-search-results .refurbishment_filter').length > 0) {
            filterPostsByRefurbishmentRoofSystem();
        }

        if (document.querySelectorAll('.roof-system-search-results .fixing_type_filter').length > 0) {
            filterPostsByFixingTypeRoofSystem();
        }

        if (document.querySelectorAll('.roof-system-search-results .guarantee_period_filter').length > 0) {
            filterPostsByGuaranteePeriod();
        }

        if (document.querySelectorAll('.roof-system-search-results .uniclass_filter').length > 0) {
            filterPostsByUniclass();
        }

        if (document.getElementById('accuroof-roof-system-results__clear')) {
            searchFilterClear.addEventListener('click', (event) => {
                event.preventDefault();
                roofTypeFilter?.forEach(roofType => {
                    roofType.checked = false;
                });
                waterproofingTypeFilter?.forEach(waterproofingType => {
                    waterproofingType.checked = false;
                });
                insulationTypeFilter?.forEach(insulationType => {
                    insulationType.checked = false;
                });
                deckTypeFilter?.forEach(deckType => {
                    deckType.checked = false;
                });
                newBuildFilter?.forEach(newBuild => {
                    newBuild.checked = false;
                });
                refurbishmentFilter?.forEach(refurbishment => {
                    refurbishment.checked = false;
                });
                fixingTypeFilter?.forEach(fixingType => {
                    fixingType.checked = false;
                });
                guaranteePeriodFilter?.forEach(guaranteePeriod => {
                    guaranteePeriod.checked = false;
                });
                uniclassFilter?.forEach(uniclass => {
                    uniclass.checked = false;
                });
                current_page = 1;
                const params = buildQuery();
                executeQuery(params);
            });
        }
    })
}

function ajaxPaginate(){
    const pageNumbers = document.querySelectorAll('.page-numbers');
    pageNumbers.forEach(pageNumber => {
        pageNumber.addEventListener('click', (event) => {
            event.preventDefault();
            const $currentPageEl = Array.from(pageNumbers).find(item => {
                return item.classList.contains('current');
            });
            const currentPage = $currentPageEl.innerHTML;

            $currentPageEl.classList.remove('current');

            if(event.target.classList.contains('next')){
                current_page = parseInt(currentPage) + 1
            }else if(event.target.classList.contains('prev')){
                current_page = parseInt(currentPage)- 1
            } else{
                event.target.classList.add('current');
                current_page = event.target.innerHTML;
            }

            const params = buildQuery();
            executeQuery(params);

        })
    });
}
function filterPostsByRoofTypeRoofSystem(){
    roofTypeFilter?.forEach(roofType => {
        roofType.addEventListener('click', debounce(function(event) {
            current_page = 1;
            const params = buildQuery();
            executeQuery(params)
        }, 500));
    });
}

function filterPostsByWaterproofingTypeRoofSystem(){
    waterproofingTypeFilter?.forEach(waterproofingType => {
        waterproofingType.addEventListener('click', debounce(function(event) {
            current_page = 1;
            const params = buildQuery();
            executeQuery(params)
        }, 500));
    });
}

function filterPostsByInsulationTypeRoofSystem(){
    insulationTypeFilter?.forEach(insulationType => {
        insulationType.addEventListener('click', debounce(function(event) {
            current_page = 1;
            const params = buildQuery();
            executeQuery(params)
        }, 500));
    });
}

function filterPostsByDeckTypeRoofSystem(){
    deckTypeFilter?.forEach(deckType => {
        deckType.addEventListener('click', debounce(function(event) {
            current_page = 1;
            const params = buildQuery();
            executeQuery(params)
        }, 500));
    });
}

function filterPostsByNewBuildRoofSystem(){
    newBuildFilter?.forEach(newBuild => {
        newBuild.addEventListener('click', debounce(function(event) {
            current_page = 1;
            const params = buildQuery();
            executeQuery(params)
        }, 500));
    });
}

function filterPostsByRefurbishmentRoofSystem(){
    refurbishmentFilter?.forEach(refurbishment => {
        refurbishment.addEventListener('click', debounce(function(event) {
            current_page = 1;
            const params = buildQuery();
            executeQuery(params)
        }, 500));
    });
}

function filterPostsByFixingTypeRoofSystem(){
    fixingTypeFilter?.forEach(fixingType => {
        fixingType.addEventListener('click', debounce(function(event) {
            current_page = 1;
            const params = buildQuery();
            executeQuery(params)
        }, 500));
    });
}

function filterPostsByGuaranteePeriod(){
    guaranteePeriodFilter?.forEach(guaranteePeriod => {
        guaranteePeriod.addEventListener('click', debounce(function(event) {
            current_page = 1;
            const params = buildQuery();
            executeQuery(params)
        }, 500));
    });
}

function filterPostsByUniclass(){
    uniclassFilter?.forEach(uniclass => {
        uniclass.addEventListener('click', debounce(function(event) {
            current_page = 1;
            const params = buildQuery();
            executeQuery(params)
        }, 500));
    });
}

function buildQuery() {
    let params = new URLSearchParams();

    if (roofTypeFilter) {
        [...roofTypeFilter].forEach((roofType) => {
            if (roofType.checked) {
                params.append('roofType[]', roofType.value);
            }
        })
    }

    if (waterproofingTypeFilter) {
        [...waterproofingTypeFilter].forEach((waterproofingType) => {
            if (waterproofingType.checked) {
                params.append('waterproofingType[]', waterproofingType.value);
            }
        })
    }

    if (insulationTypeFilter) {
        [...insulationTypeFilter].forEach((insulationType) => {
            if (insulationType.checked) {
                params.append('insulationType[]', insulationType.value);
            }
        })
    }

    if (deckTypeFilter) {
        [...deckTypeFilter].forEach((deckType) => {
            if (deckType.checked) {
                params.append('deckType[]', deckType.value);
            }
        })
    }

    if (fixingTypeFilter) {
        [...fixingTypeFilter].forEach((fixingType) => {
            if (fixingType.checked) {
                params.append('fixingType[]', fixingType.value);
            }
        })
    }

    if (guaranteePeriodFilter) {
        [...guaranteePeriodFilter].forEach((guaranteePeriod) => {
            if (guaranteePeriod.checked) {
                params.append('guaranteePeriod[]', guaranteePeriod.value);
            }
        })
    }

    if (uniclassFilter) {
        [...uniclassFilter].forEach((uniclass) => {
            if (uniclass.checked) {
                params.append('uniclass[]', uniclass.value);
            }
        })
    }

    return params
}
function executeQuery(params) {
    params.append('current_page', current_page);
    params.append('max_pages', max_pages);
    params.append('per_page', per_page);
    params.append('action', 'roof_system_filtering');

    axios.post('/wp-admin/admin-ajax.php', params).then(resp => {
         if (resp.data.html) {
            roofSystemResultsWrapper.innerHTML = resp.data.html;
        }

        ajaxPaginate();
    });
}

function debounce(func, timeout = 300){
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

ajaxRoofSystemSearchResults();