import axios from "axios";

const postSort = document.getElementById('accuroof-sortByAllContent');
const searchResultsForm = document.getElementById('accuroof-searchResultsForm');
const searchResultsInput = document.getElementById('accuroof-searchResultsInput');
const searchFilterClear = document.getElementById('accuroof-search-results__clear');
const roofTypeFilter = document.querySelectorAll('.roof_type_filter');
const waterproofingTypeFilter = document.querySelectorAll('.waterproofing_type_filter');
const insulationTypeFilter = document.querySelectorAll('.insulation_type_filter');
const deckTypeFilter = document.querySelectorAll('.deck_type_filter');
const fixingTypeFilter = document.querySelectorAll('.fixing_type_filter');
const guaranteePeriodFilter = document.querySelectorAll('.guarantee_period_filter');
const uniclassFilter = document.querySelectorAll('.uniclass_filter');
const categoryFilter = document.querySelectorAll('.category_filter');

const allContentWrapper = document.getElementById('accuroof-allContentWrapper');
let current_page = allContentWrapper?.dataset.page;
let per_page = allContentWrapper?.dataset.perPage;
let filter_type = allContentWrapper?.dataset.filterType;
let postType = allContentWrapper?.dataset.postType;
const allCount = document.getElementById('allCount');

let currentUrl = window.location.href

const ajaxAccuroofSearchResults = () => {
    document.addEventListener('DOMContentLoaded', function() {
        const tabNavLinks = document.querySelectorAll('.accuroof-search-nav-link');
        [...tabNavLinks].forEach((tabLink) => {
            tabLink.addEventListener('click', (event) => {
                event.preventDefault();
                const tabLink = event.target;
                postType = tabLink.dataset.searchFilter;
                current_page = 1;
                const params = buildQuery(searchResultsInput.value);
                executeQuery(params);
            });
        });

        if (document.getElementById('accuroof-allContentWrapper')) {
            ajaxPaginate();
        }

        if(searchResultsForm){
            searchResultsInput.addEventListener('input', filterPostsBySearch);
        }

        if (document.getElementById('accuroof-sortByAllContent')) {
            sortPostsBySelectedOrder();
        }

        if (document.getElementById('accuroof-search-results__clear')) {
            searchFilterClear.addEventListener('click', (event) => {
                event.preventDefault();
                roofTypeFilter?.forEach(roofType => {
                    roofType.checked = false;
                });
                waterproofingTypeFilter?.forEach(waterproofingType => {
                    waterproofingType.checked = false;
                });
                insulationTypeFilter?.forEach(insulationType => {
                    insulationType.checked = false;
                });
                deckTypeFilter?.forEach(deckType => {
                    deckType.checked = false;
                });
                fixingTypeFilter?.forEach(fixingType => {
                    fixingType.checked = false;
                });
                guaranteePeriodFilter?.forEach(guaranteePeriod => {
                    guaranteePeriod.checked = false;
                });
                uniclassFilter?.forEach(uniclass => {
                    uniclass.checked = false;
                });
                categoryFilter?.forEach(category => {
                    category.checked = false;
                });
                current_page = 1;
                const params = buildQuery(searchResultsInput.value);
                executeQuery(params);
            });
        }

        if (document.querySelectorAll('.search-results .roof_type_filter').length > 0) {
            filterPostsByRoofType();
        }

        if (document.querySelectorAll('.search-results .waterproofing_type_filter').length > 0) {
            filterPostsByWaterproofingType();
        }

        if (document.querySelectorAll('.search-results .insulation_type_filter').length > 0) {
            filterPostsByInsulationType();
        }

        if (document.querySelectorAll('.search-results .deck_type_filter').length > 0) {
            filterPostsByDeckType();
        }

        if (document.querySelectorAll('.search-results .fixing_type_filter').length > 0) {
            filterPostsByFixingType();
        }

        if (document.querySelectorAll('.search-results .guarantee_period_filter').length > 0) {
            filterPostsByGuaranteePeriod();
        }

        if (document.querySelectorAll('.search-results .uniclass_filter').length > 0) {
            filterPostsByUniclass();
        }

        if (document.querySelectorAll('.search-results .category_filter').length > 0) {
            filterPostsByCategory();
        }

        if (searchResultsInput) {
            current_page = 1;
            const params = buildQuery(searchResultsInput.value);
            executeQuery(params);
        }
    })
}

function sortPostsBySelectedOrder(){
    postSort?.addEventListener('input', () => {
        current_page = 1;
        const params = buildQuery(searchResultsInput.value);
        executeQuery(params);
    });
}

function filterPostsByRoofType(){
    roofTypeFilter?.forEach(roofType => {
        roofType.addEventListener('click', debounce(function(event) {
            current_page = 1;
            const params = buildQuery(searchResultsInput.value);
            executeQuery(params)
        }, 500));
    });
}

function filterPostsByWaterproofingType(){
    waterproofingTypeFilter?.forEach(waterproofingType => {
        waterproofingType.addEventListener('click', debounce(function(event) {
            current_page = 1;
            const params = buildQuery(searchResultsInput.value);
            executeQuery(params)
        }, 500));
    });
}

function filterPostsByInsulationType(){
    insulationTypeFilter?.forEach(insulationType => {
        insulationType.addEventListener('click', debounce(function(event) {
            current_page = 1;
            const params = buildQuery(searchResultsInput.value);
            executeQuery(params)
        }, 500));
    });
}

function filterPostsByDeckType(){
    deckTypeFilter?.forEach(deckType => {
        deckType.addEventListener('click', debounce(function(event) {
            current_page = 1;
            const params = buildQuery(searchResultsInput.value);
            executeQuery(params)
        }, 500));
    });
}

function filterPostsByFixingType(){
    fixingTypeFilter?.forEach(fixingType => {
        fixingType.addEventListener('click', debounce(function(event) {
            current_page = 1;
            const params = buildQuery(searchResultsInput.value);
            executeQuery(params)
        }, 500));
    });
}

function filterPostsByGuaranteePeriod(){
    guaranteePeriodFilter?.forEach(guaranteePeriod => {
        guaranteePeriod.addEventListener('click', debounce(function(event) {
            current_page = 1;
            const params = buildQuery(searchResultsInput.value);
            executeQuery(params)
        }, 500));
    });
}

function filterPostsByUniclass(){
    uniclassFilter?.forEach(uniclass => {
        uniclass.addEventListener('click', debounce(function(event) {
            current_page = 1;
            const params = buildQuery(searchResultsInput.value);
            executeQuery(params)
        }, 500));
    });
}

function filterPostsByCategory(){
    categoryFilter?.forEach(category => {
        category.addEventListener('click', debounce(function(event) {
            current_page = 1;
            const params = buildQuery(searchResultsInput.value);
            executeQuery(params)
        }, 500));
    });
}

let filterPostsBySearch = debounce(function(event) {
    event.preventDefault();
    current_page = 1;
    const searchQuery = event.target.value;
    const params = buildQuery(searchQuery);
    executeQuery(params)
}, 500)

function buildQuery(searchQuery) {
    let params = new URLSearchParams();
    if (postSort.value) {
        const orderAndDirection = postSort.value.split('_');
        params.append('order_by', orderAndDirection[0]);
        if (orderAndDirection.length > 1) {
            params.append('order_dir', orderAndDirection[1]);
        }
    }

    params.append('q', searchQuery);
    if(searchQuery !== ''){
        params.append('base_url', currentUrl + `?q=${searchQuery}&`);
    } else {
        params.append('base_url', currentUrl);
    }

    if (roofTypeFilter) {
        [...roofTypeFilter].forEach((roofType) => {
            if (roofType.checked) {
                params.append('roofType[]', roofType.value);
            }
        })
    }

    if (waterproofingTypeFilter) {
        [...waterproofingTypeFilter].forEach((waterproofingType) => {
            if (waterproofingType.checked) {
                params.append('waterproofingType[]', waterproofingType.value);
            }
        })
    }

    if (insulationTypeFilter) {
        [...insulationTypeFilter].forEach((insulationType) => {
            if (insulationType.checked) {
                params.append('insulationType[]', insulationType.value);
            }
        })
    }

    if (deckTypeFilter) {
        [...deckTypeFilter].forEach((deckType) => {
            if (deckType.checked) {
                params.append('deckType[]', deckType.value);
            }
        })
    }

    if (fixingTypeFilter) {
        [...fixingTypeFilter].forEach((fixingType) => {
            if (fixingType.checked) {
                params.append('fixingType[]', fixingType.value);
            }
        })
    }

    if (guaranteePeriodFilter) {
        [...guaranteePeriodFilter].forEach((guaranteePeriod) => {
            if (guaranteePeriod.checked) {
                params.append('guaranteePeriod[]', guaranteePeriod.value);
            }
        })
    }

    if (uniclassFilter) {
        [...uniclassFilter].forEach((uniclass) => {
            if (uniclass.checked) {
                params.append('uniclass[]', uniclass.value);
            }
        })
    }

    if (categoryFilter) {
        [...categoryFilter].forEach((category) => {
            if (category.checked) {
                params.append('category[]', category.value);
            }
        })
    }

    return params
}

function executeQuery(params) {
    params.append('current_page', current_page);
    params.append('per_page', per_page);
    params.append('action', 'accuroof_search_filtering');
    postType.split(',').forEach((type) => {
        params.append('postType[]', type);
    });

    axios.post('/wp-admin/admin-ajax.php', params).then(resp => {
        if (resp.data.redirect) {
            window.location.href = resp.data.redirect;
        }

         if (resp.data.html) {
            allContentWrapper.innerHTML = resp.data.html;
        }

        // Handle the count
        if (resp.data.allCount !== undefined) {
            let resultText = resp.data.allCount === 1 ? "Result" : "Results";
            allCount.innerHTML = resp.data.allCount + " " + resultText;
        }
        ajaxPaginate();
    });
}

function ajaxPaginate(){
    const pageNumbers = document.querySelectorAll('.page-numbers');
    pageNumbers.forEach(pageNumber => {
        pageNumber.addEventListener('click', (event) => {
            event.preventDefault();
            const $currentPageEl = Array.from(pageNumbers).find(item => {
                return item.classList.contains('current');
            });
            const currentPage = $currentPageEl.innerHTML;

            $currentPageEl.classList.remove('current');

            if(event.target.classList.contains('next')){
                current_page = parseInt(currentPage) + 1
            }else if(event.target.classList.contains('prev')){
                current_page = parseInt(currentPage)- 1
            } else{
                event.target.classList.add('current');
                current_page = event.target.innerHTML;
            }
            const params = buildQuery(searchResultsInput.value)
            executeQuery(params);

        })
    });
}

function debounce(func, timeout = 300){
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

ajaxAccuroofSearchResults();